<template>
<v-container>
    <v-card elevation="2" shaped class="prop-income">
            <v-card-title>Property Monthly Income</v-card-title>
            <v-form id="monthly-income-form" ref="mnthlyval" onsubmit="event.preventDefault();">
                <v-row style="width: 49%; padding-left:10px;">
                    <v-combobox label="Number of Units" :items="items" outlined v-model="numUnits" title="You can enter # of units manually, press enter to apply"></v-combobox>
                </v-row>
                <div class="grid-container">
                    <div v-for="(input, index) in monthlyIncome" :key="`incomeInput-${index}`">
                        <vuetify-money
                                    v-model="input.income"
                                    v-bind:label="label2"
                                    v-bind:readonly="readonly2"
                                    v-bind:disabled="disabled2"
                                    v-bind:clearable="clearable2"
                                    v-bind:options="options2"
                                    v-bind:outlined="outlined2"
                                    @input="emitEvent()"
                                    />             
                        <!-- <v-btn @click="removeField(index, monthlyIncome)" :disabled="monthlyIncome.length == 1">-</v-btn>              -->
                    </div>
                </div>
                <span class="monthly-payment">Yearly Income: ${{ yrlyIncome }}</span>
            </v-form>
        </v-card>
</v-container>  
</template>

<script>
import { setUnitIncomes, setNumUnits, setYrlyIncome } from './helperMethods';
export default {
    name: "MonthlyIncome",
    data(){
        return {
            monthlyIncome: [{income: ''}],
            label2: "Monthly Income",
            readonly2: false,
            outlined2: true,
            disabled2: false,
            clearable2: false,
            options2: {
                locale: "en-US",
                prefix: "$",
                suffix: "",
                length: 9,
                precision: 0,
            },
            baseValue: 1,
            items: [1,2,3,4,5,6,7,8],
            numUnits: 1,
            yrlyIncome: 0
        }
    },
    watch: {
        numUnits(){
            this.addField();
        },
    },
    methods: {
        calcTotalIncome(){
            var totalIncome = 0;
            var incomeArray = [];
            this.monthlyIncome.forEach((value)=> {
                totalIncome += parseFloat(value.income);
                incomeArray.push(value.income);
            });
            setUnitIncomes(incomeArray);
            this.yrlyIncome = totalIncome * 12;
            var yearlyIncome = this.yrlyIncome;
            setYrlyIncome(yearlyIncome);
            this.yrlyIncome = yearlyIncome.toLocaleString("en-US");
            if(isNaN(parseFloat(this.yrlyIncome))){
                this.yrlyIncome = 0;
            }
            return totalIncome;
        },
        emitEvent(){
            var totalIncome = this.calcTotalIncome();
            this.$root.$emit("sendMonthlyIncome", totalIncome);
        },
        addField(){
            var index = this.numUnits;
            if(this.monthlyIncome.length > this.numUnits){
                this.monthlyIncome = [{income: ''}];
                this.yrlyIncome = 0;
            }
            for(let i = this.monthlyIncome.length; i < index; i++){
                this.monthlyIncome.push({income: ''});
            }
            setNumUnits(this.numUnits);

        },
    },
    mounted:function(){
        var urlParams = new URLSearchParams(window.location.search);
        var urlData = decodeURIComponent(urlParams.get("data"));
        console.log('current url data', urlData);
        let json = atob(urlData);
        var urlJsonData = JSON.parse(json);
        this.numUnits = urlJsonData.numUnits;
        this.addField();
        var unitIncomesArr = JSON.parse(urlJsonData.unitIncomes);
        console.log('unit income array', unitIncomesArr);
        for(let i = 0; i < this.monthlyIncome.length; i++){
            console.log('the ith unit: ', unitIncomesArr[i]);
            this.monthlyIncome[i].income = unitIncomesArr[i];
        }
        this.calcTotalIncome();

        console.log('unit incomes', this.monthlyIncome);

    },
}
</script>

<style>
.grid-container{
    display: grid;
    grid-template-columns: repeat(2, 49%);
    grid-gap: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
.prop-income{
    padding-bottom: 20px;
}
</style>