<template>
    <v-content class="remove-padding">
      <div v-if="isMobile()">
        <v-row>
          <v-col>
            <MortgageCalc />
            <ExpensesVue />
          </v-col>
          <v-col>
            <MonthlyIncome />
            <ResultsVue />
          </v-col>
        </v-row>
        <v-row>
          <SavetoPDF/>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col>
            <MortgageCalc />
            <MonthlyIncome />
            <ExpensesMobile />
            <ResultsVue />
            <SavetoPDF/>
          </v-col>
        </v-row>
      </div>
  
      <div class="info-section">
        <div class="info-section-title"><b>Is it a good or bad deal?</b></div>
        <div class="info-section-content">
          <p>
            This section provides information about whether or not a potential
            real estate investment is a good deal. It takes into account the
            purchase price, rental income, expenses, and other factors to
            determine the potential return on investment.
          </p>
        </div>
  
        <div class="info-section-title"><b>Why variable expenses?</b></div>
        <div class="info-section-content">
          <p>
            Variable expenses, such as maintenance and repairs, can have a big
            impact on the profitability of a real estate investment. By taking
            these expenses into account, our calculator provides a more accurate
            picture of the potential return on investment.
          </p>
          <ul>
            <li>
                <b>Property Management:</b> Passing off property management to a professional manager can cost 7% of rent, but makes owning real estate a lot more passive.
            </li>
            <li>
                <b>Vacancy Reserves:</b> Set aside 3% of rent in an account to prepare for vacancy and cover mortgage during that time.
            </li>
            <li>
                <b>Cap-Ex Reserves:</b> Set aside 5% of rent funds separately for replacing big-ticket items like water heaters, furnaces, roof.
            </li>
            <li>
                <b>Repairs Reserves:</b> Save 5% of rent (depending on age and level of renovation) in another account to cover expenses that come up throughout the year.
            </li>
        </ul>
        </div>
  
        <div class="info-section-title"><b>Definitions</b></div>
        <div class="info-section-content">
          <p>
            Here are some definitions of terms used in our calculator:
          </p>
          <ul>
            <li><b>Rental income: </b>The amount of rent you can expect to receive each month</li>
            <li><b>Variable expenses: </b>Expenses that can vary from month to month, such as maintenance and repairs</li>
            <li><b>Fixed expenses: </b>Expenses that are the same each month, such as property taxes and insurance</li>
            <li><b>Return on Investment (ROI): </b>Cash on Cash ROI is a measure of the annual return earned on the amount of cash invested in a property</li>
            <li><b>Cash flow: </b>The amount of money left over after all expenses have been paid</li>
            <li><b>Capitalization rate (CAP): </b>The rate of return on a real estate investment based on the income generated by the property</li>
          </ul>
        </div>
      </div>
    </v-content>
  </template>
  

<script>
// import InvestmentDetails from '@/components/InvestmentDetails.vue'
import MortgageCalc from '@/components/MortgageCalc.vue';
import ExpensesVue from '@/components/LongTermExpenses.vue';
import ResultsVue from '@/components/Results.vue';
import ExpensesMobile from '@/components/LongTermExpensesMobile.vue';
import MonthlyIncome from '@/components/MonthlyIncome.vue';
import SavetoPDF from '@/components/SavetoPDF.vue';

export default {
    name: 'CalculatorView',
    components: {
    // InvestmentDetails,
    MortgageCalc,
    ExpensesVue,
    ResultsVue,
    ExpensesMobile,
    MonthlyIncome,
    SavetoPDF
    },
    isMobile: false,
    methods : {
     isMobile(){
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return false;
        } 
        else{
            return true;
        }
     }
    },
    mounted() {


  }

}
</script>


<style>
.remove-padding{
    padding: 0px !important;
}

.info-section-content {
  text-align: left;
  margin: 0 auto;
  max-width: 800px; /* adjust the width as needed */
}
  /* your styles here */
  .info-section {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }
  .info-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
  }
  .info-content {
    margin-top: 10px;
  }
</style>