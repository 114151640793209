<template>
  <html lang="en">
    <v-app class="my-app" style="background-color: rgb(245, 245, 245);">
    <!-- <v-navigation-drawer v-model="drawer" app temporary></v-navigation-drawer> -->
    <v-app-bar app style="background-color: #588B8B;">
      <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->
      <v-toolbar-title style="color: #fff">Wealth in Real Estate</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon style="color:#fff;">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link>
            <v-icon>mdi-cog-outline</v-icon>
              Settings
          </v-list-item>

          <v-list-item link>
            <v-icon>mdi-account-settings-outline</v-icon>
              Account Info
          </v-list-item>

          <v-list-item link>
            <v-icon>mdi-logout</v-icon>
              Logout
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <div id="app">
      <nav>
        <v-content style="background-color: rgb(245, 245, 245);">
          <v-tabs>
            <v-tab to="/">Home</v-tab>
            <v-tab to="/calc">Calculators</v-tab>
            <v-tab to="/properties">Manage Properties</v-tab>
          </v-tabs>
          <!-- <router-link to="/">Home</router-link> |
          <router-link to="/calc">Calculator</router-link> |
          <router-link to="/about">About Us</router-link> -->
        </v-content>
      </nav>
      <router-view/>
    </div>
  </v-app>
  </html>
</template>

<script>
export default {
  data(){
    return{
      drawer: false
    }
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.v-slide-group__content{
  background-color: rgb(245, 245, 245);
}

.v-tab--active{
  color: #E29D7E !important;
}

.v-tab:before, .v-tabs-slider{
  background-color: #FEC89A !important;
}

.v-text-field--outlined.v-input--is-focused fieldset{
  border: 2px solid #FEC89A !important;
}

.my-app .v-application .primary--text{
  color: #FEC89A !important;
  caret-color: #FEC89A !important;
}

.theme--light.v-tabs>.v-tabs-bar{
  background-color: rgb(245, 245, 245) !important;
}

</style>
