<template>
    <v-container lg class="pos-margins">
        <v-card elevation="2" shaped>
        <v-card-title>Property Expenses</v-card-title>
        <!-- <v-card class="mx-auto blurb-card" max-width="800px">
            <v-card-text style="color: #FFF">
                Default values shown for certain expenses are based on percentage of rental income <br> and will be used as estimates for profit analysis if they are unknown by the user.
            </v-card-text>
        </v-card> <br> <br> -->
    <!--    <v-row align="center" justify="center">
            <v-col lg="4">
                <v-subheader>Select Your Province</v-subheader>
                <v-combobox outlined label="Province" id="province" :items="provinces" required :rules="[v => !!v || 'Field is required']" @change="onChange"></v-combobox>
            </v-col>
        </v-row> -->
            <v-form id="roiform" style="display: block">
            <v-row>
                <v-col>
                    <h3 class="h3-style">Fixed Expenses</h3> <br>
    
                    <!-- <v-subheader> Enter your property tax: </v-subheader> --> 
                    <v-row>
                        <v-text-field outlined label="Property Tax (yr)" id="proptax" v-model="propTax"></v-text-field>
                    </v-row>
                    
                    <!-- <v-subheader> Enter your home insurance: </v-subheader> -->
                    <v-row>
                        <v-text-field outlined label="Home Insurance (yr)" id="homeinsur" v-model="homeInsur"></v-text-field>
                    </v-row>
                    
                    <!-- <v-subheader> Enter your utilities cost: </v-subheader> -->
                    <v-row>
                        <v-text-field outlined label="Snow/Lawn Care (yr)" id="snowLawn" v-model="snowLawnCare"></v-text-field>
                    </v-row>
                    <!-- <v-subheader> Enter your school tax: </v-subheader> -->
                    <v-row>
                        <v-text-field outlined label="Utilities (m)" id="utilcost" v-model="utilCost"></v-text-field>
                    </v-row>
                    <v-row align="center" justify="center">
                        <span class="fixed-expenses">Fixed expense (m): ${{ calcFixExpenses }}</span>
                    </v-row>
                </v-col>

                <v-col>
                    <h3 class="h3-style">Variable Expenses</h3> <br>

                    <v-row>
                          <!-- <v-subheader> Enter your repair & maintenance costs: </v-subheader> -->
                            <v-text-field outlined label="Repair & Maintenance Cost (m)" id="repmaintcost" v-model="repMaint" @input="setVarExpenseByVal('rmV')"></v-text-field>
                            <v-text-field label = "Percentage" class ="mt-0 pt-0" single-line type = "number" style = "width: 40px" 
                            id = "remapercent" v-model = "rmPercent" suffix = "%"  outlined></v-text-field>
                    </v-row>
    
    
                    <v-row>
                    <!--<v-subheader> Enter your capital expenditure costs: </v-subheader> -->
                    <v-text-field outlined label="Capital Expenditure Cost (m)" id="capexpcost" v-model="capExp" @input="setVarExpenseByVal('ceV')"></v-text-field>
                    <v-text-field label ="Percentage" class = "mt-0 pt-0" single-line type = "number" style = "width: 40px" id = "capexpper" v-model = "cePercent" suffix = "%" outlined></v-text-field>
                    </v-row>
    
                    <!-- <v-subheader> Enter your vacancy costs: </v-subheader> -->
                    <v-row>
                    <v-text-field outlined label="Vacancy Cost (m)" id="vaccost" v-model="vacancy" @input="setVarExpenseByVal('vacV')"></v-text-field>
                    <v-text-field label ="Percentage" class = "mt-0 pt-0" single-line type = "number" style = "width: 40px" id = "vaccostper" v-model = "vacPercent" suffix = "%" outlined></v-text-field>
                    </v-row>
                    
                    <v-row>
                    <!-- <v-subheader> Enter your property management fees: </v-subheader> --> 
                    <v-text-field outlined label="Property Management Fees (m)" id="propmanfee" v-model="propMan" @input="setVarExpenseByVal('pmV')"></v-text-field>
                    <v-text-field label ="Percentage" class = "mt-0 pt-0" single-line type = "number" style = "width: 40px" id = "propmanper" v-model = "propPercent" suffix = "%" outlined></v-text-field>
                    </v-row>
                    <v-row align="center" justify="center">
                        <span class="variable-expenses"> Variable expense (m): ${{ calcVarExpenses }}</span>
                    </v-row>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col>
                <span class="total-expenses">Total expense (m): ${{ calcTotalExpenses }}</span>
                </v-col>
            </v-row>
        </v-form>
        </v-card>
    </v-container>
    
</template>
    
    <script>
    import { setMonthlyIncome, setExpenses, setFixedCosts, setVarCosts, setPercents } from './helperMethods';
    //import VuetifyMoney from "vuetify-money/VuetifyMoney.vue";  
    export default {
    name: "ExpensesVue",
    data() {
        return {
            props: ["payment"],
            provinces: ["AB (Alberta)", "BC (British Columbia)", "MB (Manitoba)", "NB (New Brunswick)", "NL (Newfoundland & Labrador", "NT (Northwest Territories)", "NS (Nova Scotia)", "NU (Nunavut)", "ON (Ontario)", "PE (Prince Edward Island)", "QC (Quebec)", "SK (Saskatchewan)", "YT (Yukon)"],
            rmPercent: 5,
            propPercent: 7,
            vacPercent: 3,
            cePercent: 5,
            repMaint: '',
            capExp: '',
            vacancy: '',
            propMan: '',
            monthlyIncome: '',
            propTax: '',
            schoolTax: 0,
            homeInsur: '',
            utilCost: '',
            snowLawnCare: '',
            urlJsonData: null,
            manualTyped: '',
            firstLoad: true
        };
    },
    created(){
        console.log('created was called');
        this.$root.$on("sendMonthlyIncome", (value) => {
            this.monthlyIncome = value;
        })
    },
    mounted:function(){
        var urlParams = new URLSearchParams(window.location.search);
        var urlData = decodeURIComponent(urlParams.get("data"));
        console.log('current url data', urlData);
        let json = atob(urlData);
        this.urlJsonData = JSON.parse(json);

        if(this.urlJsonData != null){
            this.monthlyIncome = this.urlJsonData.monthlyIncome;
            this.repMaint = this.urlJsonData.repairMaint;
            this.capExp = this.urlJsonData.capitalExp;
            this.vacancy = this.urlJsonData.vacancy;
            this.propMan = this.urlJsonData.propManage;
            this.propTax = this.urlJsonData.propertyTax;
            this.schoolTax = this.urlJsonData.schoolTax;
            this.homeInsur = this.urlJsonData.homeInsurance;
            this.snowLawnCare = this.urlJsonData.snowLawnCare;
            this.utilCost = this.urlJsonData.utilityCost;
            this.rmPercent = this.urlJsonData.rmPercent;
            this.cePercent = this.urlJsonData.cePercent;
            this.vacPercent = this.urlJsonData.vacPercent;
            this.propPercent = this.urlJsonData.propPercent;

            console.log('data from url', this.urlJsonData);
            this.urlJsonData = null;
        }
    },
    watch: {
        monthlyIncome(){
            if(this.urlJsonData == null){
                console.log('in this watch2');
                this.calcStandardVal();
            }
        },
        propTax(){
                this.setFixedExpense("prpTx", this.propTax);
        },
        schoolTax(){
                this.setFixedExpense("sclTx", this.schoolTax);
        },
        homeInsur(){
                this.setFixedExpense("hmIn", this.homeInsur);
        },
        snowLawnCare(){
                this.setFixedExpense("snlc", this.snowLawnCare);
        }, 
        utilCost(){
                this.setFixedExpense("utlC", this.utilCost);
        }, 
        rmPercent() {
                this.setVarExpenseByPercent("rmP", this.rmPercent);  
        },
        propPercent() { 
                this.setVarExpenseByPercent("pmP", this.propPercent);

        },
        vacPercent() {
                this.setVarExpenseByPercent("vacP", this.vacPercent);
        },
        cePercent() {
            this.setVarExpenseByPercent("ceP", this.cePercent);
        }
    },
    computed: {
        calcVarExpenses(){
            var totVarExp = parseFloat(this.repMaint) + parseFloat(this.capExp) + parseFloat(this.vacancy) + parseFloat(this.propMan);
            setVarCosts(totVarExp);
            var totVarExpComma = Number(totVarExp.toFixed(2)).toLocaleString("en-US");
            if(isNaN(totVarExp)){
                return 0;
            }else{
                return totVarExpComma;
            }
        },
        calcFixExpenses(){
            var totFixExp = parseFloat(this.propTax) + parseFloat(this.schoolTax) + parseFloat(this.homeInsur) + parseFloat(this.snowLawnCare) + parseFloat(this.utilCost)*12;
            setFixedCosts(totFixExp);
            var fixedMonthlyExpense = (totFixExp/12);
            var fixedMonthlyExpenseComma = Number(fixedMonthlyExpense.toFixed(2)).toLocaleString("en-US");
            if(isNaN(fixedMonthlyExpense)){
                return 0;
            }else{
                return fixedMonthlyExpenseComma;
            }
        },
        calcYrlyIncome(){
            var yrlyIncome = (this.monthlyIncome * 12).toFixed(0);
            var yrlyIncomeComma = Number(yrlyIncome).toLocaleString("en-US");
            return yrlyIncomeComma;
        },
        calcTotalExpenses() {
            try {
                var totalExpense = parseFloat(this.calcFixExpenses.replace(/,/g, '')) + parseFloat(this.calcVarExpenses.replace(/,/g, ''));
                var totalExpenseComma = Number(totalExpense.toFixed(2)).toLocaleString("en-US");
                if(isNaN(totalExpense)){
                    return 0;
                }else{
                    return totalExpenseComma;
                }
            } catch (error) {
                return 0;
            }
        }
    },
    methods: {
        calcStandardVal() {
            var currMonthlyIncome = this.monthlyIncome;
            if (currMonthlyIncome) {
                setMonthlyIncome(currMonthlyIncome);
                this.calcBaseVal(this.rmPercent, this.cePercent, this.vacPercent, this.propPercent, currMonthlyIncome);
            }
        },
        calcBaseVal(rmPercent, cePercent, vacPercent, propPercent, monthIncome){
            this.repMaint = parseInt(monthIncome * (rmPercent / 100));
            this.capExp = parseInt(monthIncome * (cePercent / 100));
            this.vacancy = parseInt(monthIncome * (vacPercent / 100));
            this.propMan = parseInt(monthIncome * (propPercent / 100));
            setExpenses("rmP", this.repMaint);
            setExpenses("pmP", this.propMan);
            setExpenses("vacP", this.vacancy);
            setExpenses("ceP", this.capExp);
            setPercents('rmP', this.rmPercent);
            setPercents('ceP', this.cePercent);
            setPercents('vacP', this.vacPercent);
            setPercents('pmP', this.propPercent);
        },
        setVarExpenseByPercent(string, valueHolder) {
            if(this.monthlyIncome != ''){
                if (string == "rmP") {
                    this.repMaint = parseFloat(this.monthlyIncome * (valueHolder / 100)).toFixed(0);
                    setExpenses(string, this.repMaint);
                    setPercents('rmP', this.rmPercent);
                    console.log('monthly income', this.monthlyIncome);
                }
                if (string == "pmP") {
                    this.propMan = parseFloat(this.monthlyIncome * (valueHolder / 100)).toFixed(0);
                    setExpenses(string, this.propMan);
                    setPercents('pmP', this.propPercent);
                }
                if (string == "vacP") {
                    this.vacancy = parseFloat(this.monthlyIncome * (valueHolder / 100)).toFixed(0);
                    setExpenses(string, this.vacancy);
                    setPercents('vacP', this.vacPercent);
                }
                if (string == "ceP") {
                    this.capExp = parseFloat(this.monthlyIncome * (valueHolder / 100)).toFixed(0);
                    setExpenses(string, this.capExp);
                    setPercents('ceP', this.cePercent);
                }
            }
        },
        setFixedExpense(string, valueHolder){
            if(string == "prpTx"){
                setExpenses(string, valueHolder);
            }
            if(string == "sclTx"){
                setExpenses(string, valueHolder);    
            }
            if(string == "hmIn"){
                setExpenses(string, valueHolder);
            }
            if(string == "snlc"){
                setExpenses(string, valueHolder);
            }
            if(string == "utlC"){
                setExpenses(string, valueHolder);
            }
        },
        setVarExpenseByVal(string){
            if(this.monthlyIncome != ""){
                if(string == 'rmV'){
                    this.rmPercent = (this.repMaint / this.monthlyIncome * 100).toFixed(2);
                    setExpenses("rmP", this.repMaint);
                    setPercents('rmP', this.rmPercent);
                }
                if(string == 'ceV'){
                    this.cePercent = (this.capExp / this.monthlyIncome * 100).toFixed(2);
                    setExpenses("ceP", this.capExp);
                    setPercents('ceP', this.cePercent);
                }
                if(string == 'vacV'){
                    this.vacPercent = (this.vacancy / this.monthlyIncome * 100).toFixed(2);
                    setExpenses("vacP", this.vacancy);
                    setPercents('vacP', this.vacPercent);
                }
                if(string == 'pmV'){
                    this.propPercent = (this.propMan / this.monthlyIncome * 100).toFixed(2);
                    setExpenses("pmP", this.propMan);
                    setPercents('pmP', this.propPercent);
                }
            }
        }
    },
}
    </script>
    
    <style>
    .blurb-card{
        background-color: #3D9970 !important;
    }
    .row{
        margin: 0px !important;
    }
    .h3-style{
        font-weight: normal;
    }
    .pos-margins{
        margin-top: -12px;
    }

    .total-expenses {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color:  #C8553D !important; /*change to desired color*/
    }

    .fixed-expenses {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        color: #4c4c4c; /*change to desired color*/
    }

    .variable-expenses {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        color: #4c4c4c; /*change to desired color*/
    }
    </style>