<template>
  <div class="home">
    <div class="logo-container">
      <img alt="InvestCalc" class="thumbnail" src="../assets/WRE_logo.png">
      <button class="get-started-btn" @click="goToCalcPage">Get Started</button>
    </div>
    <HomePage/>
  </div>
</template>

<script>
import HomePage from '@/components/HomePage.vue'

export default {
  name: 'HomeView',
  components: {
    HomePage
  },
  methods: {
    goToCalcPage() {
      this.$router.push('/calc');
    }
  }
}
</script>

<style>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.thumbnail {
  background: transparent;
  width: 35rem;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 18rem;
}

.get-started-btn {
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  background-color: #588B8B;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 5rem;
}
</style>
