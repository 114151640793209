<template>
  <v-container>
    <div>
      <v-card elevation="2" shaped style="padding-bottom: 20px;">
          <v-card-title>Results</v-card-title>
          <v-data-table 
            :headers="headersForMetrics" 
            :items="metricsForInvestments" 
            density="compact"
            elevation="2"
            >
          <template #item="{ item }">
            <tr>
              <td class="text-align">{{ item.name }}</td>
              <td class="text-align best-case">{{ item.best }}</td>
              <td class="text-align worst-case">{{ item.worst }}</td>
              <td class="text-align capValue">{{ item.value }}</td>
            </tr>
          </template>
          <template v-for="h in headersForMetrics" v-slot:[`header.${h.value}`]>
            <th :key="h.value">
              <div v-if="h.text === 'Investment Metrics'">
                {{ h.text }}
              </div>
              <div v-if="h.text === 'Best Case'">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ h.text }}
                    </span>
                  </template>
                  <div style="white-space: pre-wrap;">{{ h.tooltip }}</div>
                </v-tooltip>
              </div>
              <div v-else-if="h.text === 'Worst Case'">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ h.text }}
                    </span>
                  </template>
                  <div style="white-space: pre-wrap;">{{ h.tooltip }}</div>
                </v-tooltip>
              </div>
              <div v-if="h.text === 'Value'">
                {{ h.text }}
              </div>
            </th>
          </template>
        </v-data-table>
        <v-btn @click="calcMonthlyProfit">Reveal Results</v-btn>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { totalExpenses, totalCost, getMonthlyIncome, getTotalCashNeeded, getDisplayAmortPeriodValue, 
         getMortgage, getPurchPrice, getExpenses, getDisplayDownPaymentValue, getDisplayInterestRateValue, 
         getAllDisplayedExpenses, getPercents, getUnitIncomes, getNumUnits, getYrlyIncome} from './helperMethods';
export default {
    name: 'ResultsVue',
    data(){
      return{
        headersForMetrics: [
          {
            text: 'Investment Metrics',
            align: 'start',
            value: 'name',
            sortable: false
          },
          {
            text: 'Best Case',
            value: 'best',
            tooltip: 'Assuming perfectly stable property.\nExcludes: Repair & Maintenance, Capital Expenditure and Vacancy costs',
            sortable: false
          },
          { text: 'Worst Case', 
            value: 'worst',
            tooltip: 'Assuming very unstable property.\nIncludes all variable expenses at their entered %',
            sortable: false
          },
          {
            text: 'Value',
            value: 'value',
            sortable: false
          },
        ],
        metricsForInvestments: [
          {
            name: 'Monthly Income (m)',
            best: '',
            worst: '',
            value: 0
          },
          {
            name: 'Expenses (m)',
            worst: 0,
            best: 0,
            value: ''
          },
          {
            name: 'Cash Flow (m)',
            worst: 0,
            best: 0,
            value: ''
          },
          {
            name: 'Return On Investment (ROI)',
            worst: 0,
            best: 0,
            value: ''
          },
          {
            name: 'Capitalization Rate (CAP)',
            worst: '',
            best: '',
            value: 0
          }
        ],
        yrlyNOI: 0,
      }
    },
    methods : {
      calcMonthlyProfit(){
        var yearlyIncome = getMonthlyIncome()*12;
        var monthlyIncome = getMonthlyIncome();
        var monthlyExpenses = totalCost();  
        var yearlyExpenses = totalCost()*12;
        var purchPrice = getPurchPrice();    
        var NOI = (yearlyIncome - yearlyExpenses).toFixed(2);
        this.yrlyNOI = NOI;
        var monthlyMortgage = getMortgage();

        var bestExpenses = (totalCost() - getExpenses('rmP') - getExpenses('ceP') -getExpenses('vacP'));
        var bestMonthlyProfit = monthlyIncome - bestExpenses - monthlyMortgage;
        var worstMonthlyProfit = monthlyIncome - monthlyExpenses - monthlyMortgage;

        this.metricsForInvestments[0].value = '$' + parseFloat(monthlyIncome).toFixed(2);
        
        this.metricsForInvestments[2].worst = '$'+ (worstMonthlyProfit).toFixed(2);
        this.metricsForInvestments[2].best = '$'+ (bestMonthlyProfit).toFixed(2);    
        
        
        var worstMonthlyExpenses = monthlyExpenses + parseFloat(monthlyMortgage);
        var bestMonthlyExpenses = bestExpenses + parseFloat(monthlyMortgage);
        this.metricsForInvestments[1].worst = '$' + (worstMonthlyExpenses).toFixed(2);
        this.metricsForInvestments[1].best = '$' + (bestMonthlyExpenses).toFixed(2);

        console.log('worst exp: ' + yearlyExpenses + ' Purchase Price:' + purchPrice + ' NOI ' + NOI);
        this.calcROI();
        this.calcCAP();
        this.setColor();
        this.updateURL();
      },
      calcROI(){
        var totalCashNeeded = getTotalCashNeeded();
        var yearlyIncome = getMonthlyIncome()*12;
        var yearlyExpense = totalExpenses()*12;
        var yearlyMortgageCost = getMortgage()*12;
        var bestExpenses = (totalCost() - getExpenses('rmP') - getExpenses('ceP') -getExpenses('vacP'))*12;

        this.metricsForInvestments[3].best =  '%' + ((((yearlyIncome - (bestExpenses + yearlyMortgageCost))/totalCashNeeded)*100).toFixed(2));
        this.metricsForInvestments[3].worst =  '%' + ((((yearlyIncome - (yearlyExpense + yearlyMortgageCost))/totalCashNeeded)*100).toFixed(2));
      },
      setColor(){
        var cleanArray = [];
        for(let i = 0; i < this.metricsForInvestments.length; i++){
          cleanArray.push(this.metricsForInvestments[i]);
        }
        try {
          for(let i = 0; i < cleanArray.length; i++){
            if(cleanArray[i].name != 'Expenses (m)'){
              if(parseFloat(cleanArray[i].best.replace(/\$|%/, "")) > 0){
                document.getElementsByClassName('best-case')[i].style.color = 'green';
              }
              if(parseFloat(cleanArray[i].best.replace(/\$|%/, "")) < 0){
                document.getElementsByClassName('best-case')[i].style.color = 'red';
              }
              if(parseFloat(cleanArray[i].worst.replace(/\$|%/, "")) > 0){
                document.getElementsByClassName('worst-case')[i].style.color = 'green';
              }
              if(parseFloat(cleanArray[i].worst.replace(/\$|%/, "")) < 0){
                document.getElementsByClassName('worst-case')[i].style.color = 'red';
              }
            }
          }    
        } catch (error) {
          console.log(error);
        }
      },
      calcCAP(){
        var purchPrice = getPurchPrice();
        var capRate = (this.yrlyNOI/purchPrice)*100;
        this.metricsForInvestments[4].value = '%' + (capRate.toFixed(2));
      },
      updateURL(){

        let data = {
          purchasePrice: getPurchPrice().toString(),
          downPayment: getDisplayDownPaymentValue().toString(),
          monthlyIncome: getMonthlyIncome().toString(),
          interestRate: getDisplayInterestRateValue().toString(),
          amortization: getDisplayAmortPeriodValue().toString(),
          repairMaint: getAllDisplayedExpenses().repMaint.toString(),
          capitalExp: getAllDisplayedExpenses().capExp.toString(),
          vacancy: getAllDisplayedExpenses().vacancy.toString(), 
          propManage: getAllDisplayedExpenses().propMan.toString(),
          propertyTax: getAllDisplayedExpenses().propTax.toString(),
          schoolTax: getAllDisplayedExpenses().schoolTax.toString(),
          homeInsurance: getAllDisplayedExpenses().homeInsur.toString(),
          snowLawnCare: getAllDisplayedExpenses().snowLawnCare.toString(),
          utilityCost: getAllDisplayedExpenses().utilCost.toString(),
          rmPercent: getPercents().rmPercent.toString(),
          cePercent: getPercents().cePercent.toString(),
          vacPercent: getPercents().vacPercent.toString(),
          propPercent: getPercents().propPercent.toString(),
          unitIncomes: JSON.stringify(getUnitIncomes()),
          numUnits: getNumUnits().toString(),
          yrlyIncome: getYrlyIncome().toString()
        };
        console.log('data for url', data);

        let json = JSON.stringify(data);
        let base64 = btoa(json);
        //dev server
        //let url = 'https://real-estate-development-82e12.web.app/calc' + `?data=${encodeURIComponent(base64)}`;
        //prd server
        let url = 'https://wealthrealestate.ca/calc' + `?data=${encodeURIComponent(base64)}`;
        //local testing
        //let url = 'http://localhost:8080/calc' + `?data=${encodeURIComponent(base64)}`;
        window.history.pushState({}, "",url);
      }
    }
}
</script>

<style>
.v-data-footer{
  display: none !important;
}
.text-align{
  text-align: left;
}
</style>