import Vue from 'vue' 
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VuetifyMoney from "vuetify-money"

// import firebase from "firebase/app"
// import 'firebase/auth'
// import 'firebase/firestore'

import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";

//DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyBzfhwbnmWcGy42qz2k4U8ne2Vo12f9aAs",
//   authDomain: "real-estate-development-82e12.firebaseapp.com",
//   projectId: "real-estate-development-82e12",
//   storageBucket: "real-estate-development-82e12.appspot.com",
//   messagingSenderId: "655867945619",
//   appId: "1:655867945619:web:fe17e3448a650499f86366",
//   measurementId: "G-PDTD20CQ34"
// };

//PRD
const firebaseConfig = {
  apiKey: "AIzaSyAWEQhpbayAFOfi84--hPDbYMuvxCmYP0Q",
  authDomain: "real-estate-investor-de9f6.firebaseapp.com",
  projectId: "real-estate-investor-de9f6",
  storageBucket: "real-estate-investor-de9f6.appspot.com",
  messagingSenderId: "680032608408",
  appId: "1:680032608408:web:c145374dda66c53a47f0ed",
  measurementId: "G-9HY7KCBS04"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

Vue.use(Vuetify);
Vue.use(VuetifyMoney);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  vuetify: new Vuetify({
    theme:{
      themes:{
        light:{
          primary: '#E29D7E'
        }
      }
    }
  }),
}).$mount('#app')

export{
  app
}
