<template>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn 
        v-on="on"
        rounded
        color="#588B8B"
        dark
        style="left: 50%;"
        >Save Options</v-btn>
      </template>
      <v-list class="save-option-list">
        <v-list-item v-on:click="showModal">Save</v-list-item>
        <v-list-item v-on:click="printToPDF">Save to PDF</v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'SavetoPDF',
    methods: {
        printToPDF(){
          window.print();
        },
        showModal(){
          this.showDialog('Enter Property Name', '');
        },
        showDialog(title, message) {
          // Create the dialog container
          var dialogContainer = document.createElement('div');
          dialogContainer.classList.add('dialog-container');

          // Create the dialog box
          var dialogBox = document.createElement('div');
          dialogBox.classList.add('dialog-box');

          // Create the title element
          var titleElement = document.createElement('h2');
          titleElement.classList.add('title');
          titleElement.innerHTML = title;

          // Create the message element
          var messageElement = document.createElement('div');
          messageElement.classList.add('message');
          messageElement.innerHTML = message;

          // Create the input field
          var inputField = document.createElement('input');
          inputField.classList.add('input-field');
          inputField.setAttribute('type', 'text');

          // Create the actions element
          var actionsElement = document.createElement('div');
          actionsElement.classList.add('actions');

          // Create the cancel button
          var cancelButton = document.createElement('button');
          cancelButton.classList.add('button', 'cancel-button');
          cancelButton.innerHTML = 'Cancel';
          cancelButton.addEventListener('click', function () {
            dialogContainer.style.display = 'none';
          });

          // Create the submit button
          var submitButton = document.createElement('button');
          submitButton.classList.add('button', 'submit-button');
          submitButton.innerHTML = 'Submit';
          submitButton.addEventListener('click', function () {
            var inputValue = [];
            var savedURL = [];

            //one property already saved, saving multiple
            if(localStorage.getItem('savedURL') && localStorage.getItem('propName')){
              try {
                savedURL = JSON.parse(localStorage.getItem('savedURL'));
                savedURL.push(location.href);
                inputValue = JSON.parse(localStorage.getItem('propName'));
                inputValue.push(inputField.value);
              } catch (error) {
                savedURL.push((localStorage.getItem('savedURL')));
                savedURL.push(location.href);
                inputValue.push((localStorage.getItem('propName')));
                inputValue.push(inputField.value);
              }
              localStorage.setItem('savedURL', JSON.stringify(savedURL));
              localStorage.setItem('propName', JSON.stringify(inputValue));
            }
            //saving the first property
            else{
              inputValue.push(inputField.value);
              savedURL.push(location.href);
              localStorage.setItem('savedURL', savedURL.toString());
              localStorage.setItem('propName', inputValue);
            }


            dialogContainer.style.display = 'none';
          });

          // Add the elements to the dialog box and container
          dialogBox.appendChild(titleElement);
          dialogBox.appendChild(messageElement);
          dialogBox.appendChild(inputField);
          actionsElement.appendChild(cancelButton);
          actionsElement.appendChild(submitButton);
          dialogBox.appendChild(actionsElement);
          dialogContainer.appendChild(dialogBox);

          // Add the dialog container to the document
          document.body.appendChild(dialogContainer);

          // Show the dialog
          dialogContainer.style.display = 'flex';
          dialogContainer.style.alignItems = 'center';
          dialogContainer.style.justifyContent = 'center';
          dialogContainer.style.position = 'fixed';
          dialogContainer.style.top = '0';
          dialogContainer.style.bottom = '0';
          dialogContainer.style.left = '0';
          dialogContainer.style.right = '0';
          dialogContainer.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
          dialogBox.style.width = '400px';
          dialogBox.style.backgroundColor = 'white';
          dialogBox.style.padding = '0';
          dialogBox.style.borderRadius = '4px';
          dialogBox.style.boxShadow = '0 5px 15px rgba(0, 0, 0, 0.5)';
          titleElement.style.margin = '0';
          titleElement.style.padding = '24px 24px 0';
          messageElement.style.margin = '0';
          messageElement.style.padding = '0 24px';
          inputField.style.margin = '0 24px 24px';
          inputField.style.padding = '8px';
          inputField.style.border = 'none';
          inputField.style.borderRadius = '4px';
          inputField.style.boxShadow = 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.1)';
          cancelButton.style.marginRight = '8px';
          actionsElement.style.display = 'flex';
          actionsElement.style.justifyContent = 'flex-end';
          actionsElement.style.marginTop = '24px';
        }
    }
}
</script>

<style>

@page {
    size: A3 landscape;
    margin: none;
}

</style>


