<template>
    <v-container>
        <v-card elevation="2" shaped>
            <div class="mortform">
                <v-form id="mortForm" ref="mortval">
                    <v-card-title>Mortgage Details</v-card-title>
                    <v-row>
                        <v-col>
                            <vuetify-money
                                v-model="propPrice" :type="tel"
                                v-bind:label="label2"
                                v-bind:readonly="readonly2"
                                v-bind:disabled="disabled2"
                                v-bind:clearable="clearable2"
                                v-bind:options="options2"
                                v-bind:outlined="outlined2"
                            />
                        </v-col>
                        <v-col>
                            <v-combobox outlined label="Down Payment" id="downpayment" v-model="dwnPayment" :items="percentages" ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-combobox outlined label="Amortization Period" id="amortperiod" v-model="amortPeriod" :items="years" ></v-combobox>
                        </v-col>
                        <v-col>
                            <v-text-field type="number" outlined label="Interest Rate" id="intrstrate" v-model="intRate" suffix="%"></v-text-field>
                        </v-col>
                    </v-row>
                    <span class="mortg-text" id="dnpymnt"></span> <br>
                    <span class="mortg-text">Total cash needed: ${{ calculateCashNeeded }}</span> <br>
                    <span class="monthly-payment" id="monthlypayment"></span>
                    <v-money v-model="calculateCashNeeded" :options="options3"></v-money>
                </v-form>
            </div>
        </v-card>
    </v-container>
    
    
    </template>
        
        <script>
        import { calcMortgage, getDownPayment, setTotalCashNeeded, setPurchPrice, getDownPaymentPercent, 
            setDisplayDownPaymentValue, setDisplayInterestRateValue, setDisplayAmortPeriodValue } from "@/components/helperMethods";
    
        export default {
        name: "MortgageCalc",
        props: ['purchPrice'],
        data() {
            return {
                monthlyPayment: 0,
                percentages: ["5%", "10%", "15%", "20%", "30%"],
                years: [25, 30],
                propPrice: "",
                dwnPayment: "",
                intRate: "",
                amortPeriod: "",
                label2: "Property Price",
                readonly2: false,
                outlined2: true,
                disabled2: false,
                clearable2: false,
                options2: {
                    locale: "en-US",
                    prefix: "$",
                    suffix: "",
                    length: 9,
                    precision: 0,
                },
            };
        },
        mounted:function(){
            var urlParams = new URLSearchParams(window.location.search);
            var urlData = decodeURIComponent(urlParams.get("data"));
            let json = atob(urlData);
            let data = JSON.parse(json);
            if(data.purchasePrice == null && data.downPayment == null && data.interestRate == null && data.amortization == null){
                this.propPrice = "0";
                this.dwnPayment = "0";
                this.intRate = "0";
                this.amortPeriod = "0";
            }else{
                this.propPrice = data.purchasePrice;
                this.dwnPayment = data.downPayment;
                this.intRate = data.interestRate;
                this.amortPeriod = data.amortization;
            }
        },
        watch: {
            propPrice() {
                this.callBack();
            },
            dwnPayment() {
                this.callBack();
            },
            intRate() {
                this.callBack();
            },
            amortPeriod() {
                this.callBack();
            }
        },
        computed: {
            dwnPaymentAmount() {
            return parseInt(this.propPrice) * (parseInt(this.dwnPayment) / 100);
            },
            calculateCashNeeded() {
                var totalCashNeeded = 0;
                if(this.dwnPayment.includes("%")){
                    totalCashNeeded = (parseInt(this.propPrice) * (parseInt(this.dwnPayment) / 100)) + (parseInt(this.propPrice) * 0.02);
                    setTotalCashNeeded(totalCashNeeded);
                    return totalCashNeeded.toLocaleString("en-US");
                }else{
                    if(this.dwnPayment == '' || this.propPrice == ''){
                        totalCashNeeded = 0;
                        return totalCashNeeded;
                    }
                    totalCashNeeded = (parseInt(this.dwnPayment))  + (parseInt(this.propPrice) * 0.02);
                    setTotalCashNeeded(totalCashNeeded);
                    return totalCashNeeded.toLocaleString("en-US");
                }

    
            },
         },
        methods: {
            showForm() {
                var form = document.getElementById("mortForm");
                if (form.style.display == "none") {
                    form.style.display = "block";
                }
                else {
                    form.style.display = "none";
                }
            },
            calcMort(propertyPrice, downPayment, interestRate, amortizPeriod) {
                var mortSpan = document.getElementById("monthlypayment");
                if (propertyPrice != 0 && (downPayment != 0) && (interestRate != 0) && (amortizPeriod != 0)) {
                    this.monthlyPayment = calcMortgage(propertyPrice, downPayment, interestRate, amortizPeriod);
                    var monthlyPaymentComma = Number(this.monthlyPayment).toLocaleString("en-US");
                    mortSpan.innerHTML = "Your monthly payment is: $" + monthlyPaymentComma;
                    //calculation needed for biweekly payments (find formula)
                    //or: $' + ((this.monthlyPayment/2).toFixed(2)) + ' biweekly';
                }
                else {
                    //dont display
                    mortSpan.innerHTML = "Your monthly payment is: $" + 0;
                }
            },
            callBack() {
                this.calcMort(this.propPrice, this.dwnPayment, this.intRate, this.amortPeriod);
                this.setDownPayment();
                setDisplayDownPaymentValue(this.dwnPayment);
                setDisplayInterestRateValue(this.intRate);
                setDisplayAmortPeriodValue(this.amortPeriod);
                //this.totalCashNeeded();
                setPurchPrice(parseInt(this.propPrice));
            },
            setDownPayment() {
                var downPaymentSpan = document.getElementById("dnpymnt");
                if (this.dwnPayment != 0 && this.propPrice != 0 && this.intRate != 0 && this.amortPeriod != 0) {
                    if (this.dwnPayment.includes("%")) {
                        downPaymentSpan.innerHTML = "Your down payment amount is: $" + getDownPayment().toLocaleString("en-US") + " based on " + this.dwnPayment;
                    }
                    else {
                        downPaymentSpan.innerHTML = "Your down payment percentage is: %" + getDownPaymentPercent() + " based on " + this.dwnPayment.toLocaleString("en-US");
                    }
                }
            },
        },
    }
        </script>
        
<style>
.mortform{
    justify-content: center;
}
.formatted-input{
    width: 200px;
} 

.monthly-payment {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #C8553D !important   ; /*change to desired color*/
}
.mortg-text {
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    color: #4c4c4c; /*change to desired color*/
}

</style>