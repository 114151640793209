var monthlyIncome = 0;
var mortgage = 0;
var gv_downPayment = 0;
var totalCashNeeded= 0;
var percent;
var purchPrice = 0;
var fixedCosts = 0;
var varCosts = 0;
var gv_downPaymentPercent = 0;
var gv_displayDownPaymentValue = 0;
var gv_displayInterestRateValue = 0;
var gv_displayAmortPeriodValue = 0;
var purchPriceFromUrl = 0;
var numUnits = 1;

var expenses = {
    repMaint: 0,
    capExp: 0,
    vacancy: 0,
    propMan: 0,
    propTax: 0,
    schoolTax: 0,
    homeInsur: 0,
    snowLawnCare: 0,
    utilCost: 0,
}

var percents = {
    rmPercent: 5,
    cePercent: 5,
    vacPercent: 3,
    propPercent: 7
}

var unitIncomes = [];
var yrlyIncome = 0;

function setYrlyIncome(income){
    yrlyIncome = income;
}

function getYrlyIncome(){
    return yrlyIncome;
}

function setNumUnits(units){
    numUnits = units;
}

function getNumUnits(){
    return numUnits;
}

function setUnitIncomes(income){
    for(let i = 0; i < income.length; i ++){
        unitIncomes[i] = income[i];
    }
}

function getUnitIncomes(){
    return unitIncomes;
}

function setPercents(string, value){
    if(string == 'rmP'){
        percents.rmPercent = value;
    }
    if(string == 'ceP'){
        percents.cePercent = value;
    }
    if(string == 'vacP'){
        percents.vacPercent = value;
    }
    if(string == 'pmP'){
        percents.propPercent = value;
    }
}

function getPercents(){
    return percents;
}

function setFixedCosts(val){
    fixedCosts = val/12;
    console.log('fixed costs monthly', fixedCosts);
}

function setVarCosts(val){
    varCosts = val;
    console.log('variable costs', varCosts);
}

function totalCost(){
    return varCosts + fixedCosts;
}

function setPurchPrice(price){
    purchPrice = price;
    //console.log('purchase price', purchPrice);
}

function getPurchPrice(){
    //console.log('the purchase price', purchPrice);
    return purchPrice;
}

function calcMortgage(propPrice, dwnPaymentOrPercent, intRate, amortPeriod){
    intRate = parseFloat(intRate);
    if(intRate > 0) {
        intRate = intRate/100/12;
    }
    else{
        intRate = (intRate)/12;
    }
    
    if(dwnPaymentOrPercent.includes('%')){
        percent = '%';
        dwnPaymentOrPercent = dwnPaymentOrPercent.substring(0, dwnPaymentOrPercent.length-1); //removing the %
        dwnPaymentOrPercent = (parseFloat(dwnPaymentOrPercent))/100; //from the % converting to a decimal
        dwnPaymentOrPercent = dwnPaymentOrPercent*propPrice; //calculating the actual downpayment
        gv_downPayment = dwnPaymentOrPercent; //storing the monetary value of downpayment in a variable
    }
    else{
        dwnPaymentOrPercent = parseFloat(dwnPaymentOrPercent);
        //downPaymentPercent = dwnPaymentOrPercent;
        gv_downPaymentPercent = (dwnPaymentOrPercent/propPrice)*100;
        gv_downPaymentPercent = gv_downPaymentPercent.toFixed(2);
    }
    var difference = propPrice - dwnPaymentOrPercent;

    var top = intRate*((1 + intRate)**(amortPeriod*12));
    var bottom = (1 + intRate)**(amortPeriod*12) - 1;

    mortgage = difference*(top/bottom);
    mortgage = mortgage.toFixed(2);

    return mortgage;
}

function setExpenses(type, val){
    if (type == "rmP") {
        expenses.repMaint = val;
    }
    if (type == "pmP") {
        expenses.propMan = val;
    }
    if (type == "vacP") {
        expenses.vacancy = val;
        
    }
    if (type == "ceP") {
        expenses.capExp = val;
        
    }
    if (type == "ceV") {
        expenses.repMaint = val;
        
    }
    if(type == "prpTx"){
        expenses.propTax = val;
        
    }
    if(type == "sclTx"){
        expenses.schoolTax = val; 
         
    }
    if(type == "hmIn"){
        expenses.homeInsur = val;
        
    }
    if(type == "snlc"){
        expenses.snowLawnCare = val;
    }
    if(type == "utlC"){
        expenses.utilCost = val;
        
    }
    
}

function getExpenses(type){
    if (type == "rmP") {
        return parseFloat(expenses.repMaint) ;
    }
    if (type == "pmP") {
        return parseFloat(expenses.propMan);
    }
    if (type == "vacP") {
        return parseFloat(expenses.vacancy);
    }
    if (type == "ceP") {
        return parseFloat(expenses.capExp);
    }
    if (type == "ceV") {
        return parseFloat(expenses.repMaint);
    }
    if(type == "prpTx"){
        return parseFloat(expenses.propTax)/12;
    }
    if(type == "sclTx"){
        return parseFloat(expenses.schoolTax)/12;    
    }
    if(type == "hmIn"){
        return parseFloat(expenses.homeInsur)/12;
    }
    if(type == "snlc"){
        return parseFloat(expenses.snowLawnCare);
    }
    if(type == "utlC"){
        return parseFloat(expenses.utilCost);
    }
}

function totalExpenses(){
    var totExpense = parseFloat(expenses.capExp) + parseFloat(expenses.homeInsur)/12 + parseFloat(expenses.propMan) + parseFloat(expenses.repMaint) + parseFloat(expenses.schoolTax)/12 + parseFloat(expenses.snowLawnCare)/12 + parseFloat(expenses.utilCost) + parseFloat(expenses.vacancy) + parseFloat(expenses.propTax)/12;
    return totExpense;
}

function setMonthlyIncome(payment){
    monthlyIncome = payment;
}

function getMortgage(){
    return mortgage;
}

function getDownPayment(){
    return gv_downPayment;
}

function getDownPaymentPercent(){
    return gv_downPaymentPercent;
}

function getPercent(){
    if(percent)
    {
        return percent;
    }
}

function getMonthlyIncome(){
    return monthlyIncome;
}

function setTotalCashNeeded(inputTotalCash){
    totalCashNeeded =  inputTotalCash;
}

function getTotalCashNeeded(){
    return totalCashNeeded;
}

function setURLParams(purchprice){
    purchPriceFromUrl = purchprice;
}

function getURLParams(){
    return purchPriceFromUrl;
}

function setDisplayDownPaymentValue(value){
    gv_displayDownPaymentValue = value;
}

function getDisplayDownPaymentValue(){
    return gv_displayDownPaymentValue;
}

function setDisplayInterestRateValue(value){
    gv_displayInterestRateValue = value;
}

function getDisplayInterestRateValue(){
    return gv_displayInterestRateValue;
}

function setDisplayAmortPeriodValue(value){
    gv_displayAmortPeriodValue = value;
}

function getDisplayAmortPeriodValue(){
    return gv_displayAmortPeriodValue;
}

function getAllDisplayedExpenses(){
    return expenses;
}


export{setMonthlyIncome, getMonthlyIncome, calcMortgage, getMortgage, getDownPayment, 
        getPercent, setExpenses, getExpenses, totalExpenses, setTotalCashNeeded, getTotalCashNeeded, 
        setPurchPrice, getPurchPrice, setFixedCosts, setVarCosts, totalCost, setURLParams, getURLParams, 
        getDownPaymentPercent, setDisplayDownPaymentValue, getDisplayDownPaymentValue, 
        setDisplayInterestRateValue, getDisplayInterestRateValue, setDisplayAmortPeriodValue, 
        getDisplayAmortPeriodValue, getAllDisplayedExpenses, setPercents, getPercents, setUnitIncomes, 
        getUnitIncomes, setNumUnits, getNumUnits, setYrlyIncome, getYrlyIncome}