import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CalculatorsView from '../views/CalculatorsView.vue'
// import ShortTermCalculator from '../views/ShortTermCalculator.vue'
// import LongTermCalculator from '../views/LongTermCalculator.vue'
// import AirbnbCalculator from '../views/AirbnbCalculator.vue'
// import RefinanceCalculator from '../views/RefinanceCalculator.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/properties',
    name: 'properties',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ManageProperty.vue')
  },
  {
    path: '/calc',
    name: 'calculators',
    component: CalculatorsView
  },
  // {
  //   path: '/calc/short-term',
  //   name: 'short-term',
  //   component: ShortTermCalculator
  // },
  // {
  //   path: '/calc/long-term',
  //   name: 'long-term',
  //   component: LongTermCalculator
  // },
  // {
  //   path: '/calc/airbnb',
  //   name: 'airbnb',
  //   component: AirbnbCalculator
  // },
  // {
  //   path: '/calc/refinance',
  //   name: 'refinance',
  //   component: RefinanceCalculator
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
