<template>
    <v-content>
      <v-tabs id="second-row-tabs" class="second-row-tabs" centered>
        <v-tab @click="switchComponent('LR')">Long Term</v-tab>
        <!-- <v-tab @click="switchComponent('ST')" disabled>Short Term</v-tab> -->
        <v-tab @click="switchComponent('RF')" disabled>Refinance</v-tab>
        <v-tab @click="switchComponent('AB')" disabled>AirBnb</v-tab>
        <v-tab @click="switchComponent('SL')" disabled>Selling</v-tab>
      </v-tabs>
      <component :is="currentComponent"/>
      <v-row align="center" justify="center" class="saveto-pdf">
      </v-row>


    </v-content>
  </template>

<script>
import LongTerm from '@/views/LongTermCalculator.vue';
// import ShortTerm from '@/views/ShortTermCalculator.vue';
// import Refinance from '@/views/RefinanceCalculator.vue';
// import Airbnb from '@/views/AirbnbCalculator.vue';

export default {
    name: 'CalculatorsView',
    components: {
        LongTerm,
        // ShortTerm,
        // Refinance,
        // Airbnb
    },
    data() {
        return {
            currentComponent: 'LongTerm'
        };
    },
    methods: {
        switchComponent(val){
            if(val == 'LR'){
                this.currentComponent = 'LongTerm';
            }
            // if(val == 'ST'){
            //     this.currentComponent = 'ShortTerm';
            // }
            if(val == 'RF'){
                this.currentComponent = 'Refinance';    
            }
            if(val == 'AB'){
                this.currentComponent = 'Airbnb';
            }
            if(val == 'SL'){
                this.currentComponent = 'Selling';
            }
        }
    }
}
</script>


<style>
.second-row-tabs {
  margin-top: -100px;
  padding-top: 10px;
}
#second-row-tabs .v-tabs__item {
    font-size: 14px !important;
}
.saveto-pdf {
    padding-bottom: 30px;
}

</style>